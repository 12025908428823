export function N_hours_from_minutes(nhours) {
  return nhours / 60;
}

const oneMinute = 60 * 1000;

export const getTimeAfterNumberOfMinutes = (startDateTimeValue, n) => {
  // Step 1: Get the time in milliseconds after adding n minutes
  const date = new Date(new Date(startDateTimeValue).getTime() + n * oneMinute);

  // Step 2: Set seconds and milliseconds to 0 to ensure it ends on the exact minute
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

// convert seconds into days, hours, minutes
export const formatTime = (seconds) => {
  // Convert seconds to days, hours, and minutes
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Construct the formatted string
  let formattedString = "";
  if (days > 0) {
    formattedString += `${days} day${days === 1 ? "" : "s"}`;
  }
  if (hours > 0) {
    formattedString += `${formattedString ? "  " : ""}${hours} hour${
      hours === 1 ? "" : "s"
    }`;
  }
  if (minutes > 0 || (days === 0 && hours === 0)) {
    formattedString += `${formattedString ? "  " : ""}${minutes} minute${
      minutes === 1 ? "" : "s"
    }`;
  }

  if (minutes == 0 && days == 0 && hours == 0) {
    return `${seconds} second${seconds === 1 ? "" : "s"}`;
  }

  // Return the formatted string
  return formattedString;
};

//constructing the query string based on the search parameters
const constructQueryString = (params, keys) => {
  return keys
    .map((key) =>
      params.get(key) !== null ? `${key}=${params.get(key)}&` : ""
    )
    .join("");
};

// hotdealKeys(Search parameters) keys
export const getHotdealUrl = (searchParams) => {
  const hotdealKeys = [
    "ae_country_id",
    "ae_seller_allowed",
    "ae_display_for",
    "ae_product_type_id",
    "ae_recurring",
    "ae_sort",
    "ae_start_day",
    "ae_end_day",
    "ae_keyword",
    "ae_per_page",
    "ae_page",
    "hotdeal_page",
  ];
  const defaultKeys = [
    "a_country_id",
    "a_seller_allowed",
    "a_display_for",
    "a_product_type_id",
    "a_status",
    "a_sort",
    "a_keyword",
    "a_per_page",
    "a_page",
  ];

  //returning navigate back path with applied filters(search parameters)
  const hotdealId = searchParams.get("hotdeal_id");
  if (hotdealId !== null) {
    return `/hotdeals/${hotdealId}?${constructQueryString(
      searchParams,
      hotdealKeys
    )}`;
  } else {
    return `/hotdeals?${constructQueryString(searchParams, defaultKeys)}`;
  }
};

// auctionKeys(Search parameters) keys
export const getAuctionUrl = (searchParams) => {
  const auctionKeys = [
    "ae_country_id",
    "ae_seller_allowed",
    "ae_display_for",
    "ae_product_type_id",
    "ae_recurring",
    "ae_sort",
    "ae_start_day",
    "ae_end_day",
    "ae_keyword",
    "ae_per_page",
    "ae_page",
    "auction_page",
  ];
  const defaultKeys = [
    "a_country_id",
    "a_seller_allowed",
    "a_display_for",
    "a_product_type_id",
    "a_status",
    "a_sort",
    "a_keyword",
    "a_per_page",
    "a_page",
  ];

  //returning navigate back path with applied filters(search parameters)
  const auctionId = searchParams.get("auction_id");
  if (auctionId !== null) {
    return `/auction/${auctionId}?${constructQueryString(
      searchParams,
      auctionKeys
    )}`;
  } else {
    return `/auctions?${constructQueryString(searchParams, defaultKeys)}`;
  }
};

//generating query params
export const generateQueryString = (params) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== "" && value !== undefined && value !== null) {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};

//helper function to truncate the text to n words.
export const truncateText = (text, charLimit) => {
  if (text.length > charLimit) return text.substring(0, charLimit) + "...";
  return text;
};

//function to check the text overflow status and ellipsis in column (boolean value)
export const getOverflowState = (refs) => {
  const newOverflowStates = {};
  Object.keys(refs).forEach((key) => {
    const ref = refs[key];
    if (ref) {
      const isTextOverflowing = ref.scrollWidth > ref.clientWidth;
      newOverflowStates[key] = isTextOverflowing;
    }
  });
  return newOverflowStates;
};

export const constructQueryStringFromIds = (ids, paramName) => {
  return ids.map((id, index) => `${paramName}[${index}]=${id}`).join("&");
};

// Common function to format currency with Euro sign before the numbers
export const formatCurrency = (amount) => {
  if (amount === undefined || amount === null) return "-";

  // Ensure amount is a number (integer or float)
  const numericAmount = parseFloat(amount);

  if (isNaN(numericAmount)) return "-";

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    signDisplay: "never", // This removes the automatic placement of the Euro sign
  });

  // Format the number and manually add the Euro sign at the beginning
  return `€ ${formatter
    .format(numericAmount / 100)
    .replace("€", "")
    .trim()}`;
};

// Reusable component for displaying formatted currency
export const CurrencyDisplay = ({ amount, title }) => {
  return <b title={title || "Amount"}>{formatCurrency(amount)}</b>;
};

export const CountriesLists = async () => {
  let getCountries = JSON.parse(localStorage.getItem("countries") || "{}");
  if (getCountries.length > 0) {
    return getCountries;
  }
};

export const getProductTypeName = (productId) => {
  const products = JSON.parse(localStorage.getItem("productType"));
  if (products && products.length > 0) {
    const product = products.find((product) => product.id === productId);
    if (product) {
      return product.item_name.en;
    }
  }
  return "-";
};
